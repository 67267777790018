* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

.MuiPopover-root .MuiPaper-root {
  box-shadow: 0px 0px 10px rgba(56, 56, 116, 0.25);
  border-radius: 10px;
  margin-top: 10px;
}
.MuiAutocomplete-popper .MuiPaper-root {
  box-shadow: 0px 0px 10px rgba(56, 56, 116, 0.25);
  border-radius: 10px;
}

.white-tooltip div{
  background: var(--white);
  box-shadow: 0px 4px 10px rgba(56, 56, 116, 0.15);
  color: var(--darkgrey);
  padding: 10px;
  font-size: 14px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--lightgrey);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--lightgrey);
}